import { Stack, Typography } from '@mui/material'
import { capitalize } from 'lodash'
import ProvenIconWithText from '../../proven-icon-with-text'
import React from 'react'

function SavingLegend({
  isOneTime,
  frequency,
  frequencyUnit,
  sx = {},
  showOnlyStaticLegend = false,
  showCancelLegend = false,
  showReformulationLegend = false
}) {
  return (
    <Stack
      gap={2}
      justifyContent="flex-start"
      sx={{
        alignItems: { xs: 'center', md: 'flex-start' },
        ...sx
      }}
    >
      {!showOnlyStaticLegend && (
        <Typography variant="footnote" color="gray.blueGray">
          {`${isOneTime ? '' : 'Subscribe & '}Save. `}
          {isOneTime
            ? 'Ships Once. 2-Month Supply.'
            : `Auto-Refill Every ${frequency} ${capitalize(frequencyUnit)}s.`}
        </Typography>
      )}
      <Stack direction="row" gap={3}>
        {showCancelLegend && (
          <ProvenIconWithText
            name="shield"
            type="system"
            gap={1}
            color="gray.elysian"
            iconSize="1.125rem"
          >
            <Typography variant="footnote" color="gray.blueGray">
              Cancel anytime
            </Typography>
          </ProvenIconWithText>
        )}
        <ProvenIconWithText
          name="shipping"
          type="system"
          gap={1}
          color="gray.elysian"
          iconSize="1.125rem"
        >
          <Typography variant="footnote" color="gray.blueGray">
            Free shipping
          </Typography>
        </ProvenIconWithText>
        {showReformulationLegend && (
          <ProvenIconWithText
            name="free-reformulations"
            type="system"
            gap={1}
            color="gray.elysian"
            iconSize="1.125rem"
          >
            <Typography variant="footnote" color="gray.blueGray">
              Free reformulation
            </Typography>
          </ProvenIconWithText>
        )}
      </Stack>
    </Stack>
  )
}

export default SavingLegend
